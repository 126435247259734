@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.otf") format("opentype");
}

.menuTransition {
  transition: height 1s;
  height: 200px;
}

.menuCloseTransition {
  transition: height 1s;
  height: 0px;
}
.menuTransitionSlide {
  transition: width 1s;
  width: 100%;
}

.menuCloseTransitionSlide {
  transition: width 1s;
  width: 0px;
}

span,
p,
button,
a,
h1,
label,
input {
  font-family: "Montserrat", sans-serif;
}

.gridLayout {
  @apply grid-cols-xsmall small:grid-cols-small medium:grid-cols-medium grid;
}

.gridCards24 {
  @apply grid  small:grid-cols-4 xsmall:grid-cols-2;
}

.gridCardsColorName {
  @apply grid medium:grid-cols-card_grid_medium_color_name small:grid-cols-card_grid_small xsmall:grid-cols-card_grid_xsmall;
}

.gridCardsNameSerie {
  @apply grid small:grid-cols-2  smallTwo:grid-cols-3 large:grid-cols-4;
}

.cardSerie {
  @apply large:w-card_large_series large:h-card_large_series medium:w-card_medium_series medium:h-card_medium_series small:w-card_small_series small:h-card_small_series xsmall:w-card_xsmall_series xsmall:h-card_xsmall_series;
}

.cardNameSerie {
  @apply medium:w-card_medium_series_name medium:h-card_medium_series_name small:w-card_small_series_name small:h-card_small_series_name xsmall:h-card_xsmall_series_name xsmall:w-card_xsmall_series_name;
}

.cardColorSerie {
  @apply xsmall:h-card_xsmall_series_name_color small:h-card_small_series_name_color medium:h-card_medium_series_name_color large:min-h-card_large_series_name_color;
}

.buttonNameSerie {
  @apply cursor-pointer border-2 border-black flex gap-x-2 items-center  pl-2 hover:bg-neutral20 active:bg-black active:text-white active:font-bold;
}

.buttonNameSerie{
  @apply  medium:w-[128px] small:w-[125px] xsmall:w-[140px] xsmall:h-8 small:h-6;
}

.buttonNameSerie img {
  @apply h-4;
}

.buttonNameSerie :nth-child(2){
  @apply large:text-[11px] medium:text-[10px] xsmall:text-[12px] small:text-[10px] smallTwo:text-[10px];
}

.formGrid {
  @apply grid xsmall:grid-cols-xsmall small:grid-cols-small medium:grid-cols-form_medium pt-forms;
}

.formloging {
  @apply bg-red-400;
}

.text-shadow {
  text-shadow: 0px 3px 6px rgba(150, 150, 150, 1);
}

/* scroll */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; 
  border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: red;  */
}

.prueba {
  grid-template-columns: repeat(3, 1fr);
}
